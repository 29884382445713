import React, { useEffect, useState, useRef } from 'react';
import { collection, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase'; 
import styles from './styles.module.scss';

interface ServicesProps {
  isOpen: boolean;
  serviceId: string;
}

interface ServiceData {
  title: string;
  description: string;
  imageUrls: string[];
}

export default function Services({ isOpen, serviceId }: ServicesProps) {
  const [serviceData, setServiceData] = useState<ServiceData | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const imagesContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchServiceData = async () => {
      try {
        const docRef = doc(collection(db, 'posts'), serviceId);
        const docSnap = await getDoc(docRef);
  
        if (docSnap.exists()) {
          const data = docSnap.data() as ServiceData;
          setServiceData({
            title: data.title || '',
            description: data.description || '',
            imageUrls: data.imageUrls || [] 
          });
          console.log('Document data:', data);
        } else {
          console.log('No such document!');
          setServiceData({
            title: '',
            description: '',
            imageUrls: [] 
          });
        }
      } catch (error) {
        console.error('Error fetching service data: ', error);
      }
    };
  
    if (serviceId) {
      fetchServiceData();
    }
  }, [serviceId]);

  if (!serviceData) {
    return <div>Loading...</div>;
  }

  const handleMouseDown = (e: React.MouseEvent) => {
    setIsDragging(true);
    setStartX(e.pageX - (imagesContainerRef.current?.offsetLeft || 0));
    setScrollLeft(imagesContainerRef.current?.scrollLeft || 0);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - (imagesContainerRef.current?.offsetLeft || 0);
    const walk = (x - startX) * 1; // Ajuste o fator de multiplicação conforme necessário
    if (imagesContainerRef.current) {
      imagesContainerRef.current.scrollLeft = scrollLeft - walk;
    }
  };

  return (
    <div className={styles.containerServices}>
      <div className={styles.servicesContainer}>
        <div key={serviceId} id={serviceId} className={styles.item}>
          <div className={styles.card}>
            <div className={styles.textheader}>
              <span>{serviceData.title}</span>
              <p>{serviceData.description}</p>
            </div>
            <div
              className={styles.imagesContainer}
              onMouseDown={handleMouseDown}
              onMouseLeave={handleMouseLeave}
              onMouseUp={handleMouseUp}
              onMouseMove={handleMouseMove}
              ref={imagesContainerRef}
            >
              <div className={styles.images}>
                {serviceData.imageUrls.map((imageSrc, index) => (
                  <img
                    key={index}
                    className={styles.imagesItem}
                    src={imageSrc}
                    alt={`Image ${index}`}
                    draggable="false"
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
