import { ReactNode } from "react";

interface TextsProps{
    colorText: string;
    text:string;
    icon?: ReactNode; 
    className?: string;
}



const TitleText = ({colorText,  text}: TextsProps) => {
    return(
        <h1 style={{ color: colorText, fontFamily: 'sans-Serif' }}>
            {text}
        </h1>
    )
}
const SubTexts = ({colorText,  text, className}: TextsProps) => {
    return(
        <h2 className={className} style={{ color: colorText, fontFamily: 'sans-Serif' }}>
            {text}
        </h2>
    )
}
const SpanTexts = ({colorText,  text, icon, className}: TextsProps) => {
    return(
        <span className={className} style={{ color: colorText, fontFamily: 'sans-Serif', display: "flex" }}>
            {icon}{text}
        </span>
    )
}

export {TitleText, SubTexts, SpanTexts};