import styles from './styles.module.scss';
import { Logo } from '../../UI/Logo';
import { SpanTexts, SubTexts } from '../../UI/Text';
import { FaPhone } from "react-icons/fa6";
import { IoIosMail } from "react-icons/io";
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <div className={styles.footerContainer}>
        <div className={styles.footerCard}>
      <div className={styles.logo}>
        <Logo height={100} width={100} />
      </div>
      <div className={styles.contact}>
        <SpanTexts icon={<FaPhone />} text="(51) 999380857" colorText='white' />
        <SpanTexts icon={<IoIosMail />} text="daniel.agrimensor@gmail.com " colorText='white' />
      </div>
      <div className={styles.siteMap}>
        <SubTexts text="Mapeamento do site" colorText='white' />
        <Link to="/">
        <SpanTexts text="Início" colorText='white' />
        </Link>
        <Link to="/contact">
        <SpanTexts text="Contato" colorText='white' />
        </Link>
      </div>
      </div>
      <div className={styles.copyright}>
        <SpanTexts  colorText='white' text='Copyringht © Devroom-2024' />
      </div>
    </div>
  );
}
