import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/global.scss';
import App from './App';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Contact from './Pages/Contact';
import Login from './Pages/Auth';
import PostForm from './Pages/Post';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <Router>
     <Routes>
        <Route path="/" element={<App />} />
        <Route path="/contact" element={<Contact animationStep={1} />} />
        <Route path="/login" element={<Login />} />
        <Route path="post" element={<PostForm />} />
    </Routes>
    </Router>
  </React.StrictMode>
);


