import { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase'; // Atualize o caminho conforme necessário
import { Logo } from '../../UI/Logo';
import styles from './styles.module.scss';
import { TitleText, SubTexts, SpanTexts } from '../../UI/Text';
import { Link } from 'react-router-dom';
import Button from '../../UI/Button';
import { IoIosMail } from 'react-icons/io';
import { FaPhone } from 'react-icons/fa6';


type navBarProps = {
  Home: string;
  Contact: string;
  openServices: (id: string) => void;
  closeServices: (value: boolean) => void;
};
interface PostData {
  title: string;
  description: string;
  imageUrls: string[];
}

export default function Navbar({ Home, Contact, openServices, closeServices }: navBarProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [menuItems, setMenuItems] = useState<PostData[]>([]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const checkScreenSize = () => {
    setIsSmallScreen(window.innerWidth < 1366);
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  useEffect(() => {
    const fetchMenuItems = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'posts'));
        const items: PostData[] = [];
        querySnapshot.forEach((doc) => {
          items.push(doc.data() as PostData);
        });
        setMenuItems(items);
      } catch (error) {
        console.error('Error fetching menu items: ', error);
      }
    };

    fetchMenuItems();
  }, []);

  const handleCloseServices = () => {
    closeServices(true);
  };

  const handleOpenServices = (id: string) => {
    openServices(id);
  };

  return (
    <>
      <div className={styles.navBarContainer}>
        <div className={styles.navBarCardItem}>
          <div className={styles.logoCard}>
            <Logo width={isSmallScreen ? 80 : 120} height={isSmallScreen ? 80 : 120} />
          </div>
          <div className={`${styles.menuToggle} ${isOpen ? styles.open : ''}`} onClick={toggleMenu}>
            <div className={styles.hamburger}></div>
            <div className={styles.hamburger}></div>
            <div className={styles.hamburger}></div>
          </div>
          <div className={`${styles.navBarCard} ${isOpen ? styles.open : ''}`}>
            <ul className={styles.navList}>
              <li className={styles.navItem}>
                <Link to={Home} onClick={handleCloseServices}>
                  <SpanTexts colorText="white" text="Início" className={styles.hoverEffect} />
                </Link>
              </li>
              <li className={styles.navItem}>
                <Link to={Contact} onClick={handleCloseServices}>
                  <SpanTexts colorText="white" text="Contato" className={styles.hoverEffect} />
                </Link>
              </li>
            </ul>
            <ul className={styles.navListItems}>
              <TitleText colorText="white" text="Áreas de atuação" />
              {menuItems.map((item, index) => (
                <li key={index} id={item.title.toLowerCase().replace(/\s+/g, '_')} className={styles.navItem} onClick={() => handleOpenServices(item.title)}>
                  <SubTexts colorText="white" text={item.title} className={styles.hoverEffect} />
                </li>
              ))}
            </ul>
            <div className={styles.buttonCard}>
              <Button
                text="Entre em contato"
                onClick={() => {
                  window.open('https://wa.me/555199380857/?text=Ola gostaria de saber mais informações!', '_blank');
                }}          
              />
              <div className={styles.icons}>
                <a href="tel:+55-51-999380857" title="Telefone: +55-51 99380857">
                  <FaPhone />
                </a>
                <a href="mailto:daniel.agrimensor@gmail.com" title="E-mail: daniel.agrimensor@gmail.com">
                  <IoIosMail className={styles.mailTo} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
