import { FaPhone } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { useState } from "react";
import Button from "../../app/UI/Button";
import { TitleText, SpanTexts, SubTexts } from "../../app/UI/Text";
import Footer from "../../app/components/Footer";
import Navbar from "../../app/components/Navbar";
import Services from "../Services";
import styles from "./styles.module.scss";

interface ContactProps {
  openServicesProps?: string | null;
  animationStep: number;
}

export default function Contact({ openServicesProps, animationStep }: ContactProps) {
  const [openServiceAbout, setOpenService] = useState(false);
  const [serviceId, setServiceId] = useState(openServicesProps);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const [error, setError] = useState("");

  const openServices = (id: string) => {
    setOpenService(true);
    setServiceId(id);
  };

  const closeServicesFunction = () => {
    setOpenService(false);
  };

  const handleWhatsAppMessage = () => {
    if (!name || !email || !phone || !message) {
      setError("Por favor, preencha todos os campos.");
      return;
    }

    const whatsappMessage = `Olá, meu nome é ${name}. Meu email é ${email} e meu telefone é ${phone}. Mensagem: ${message}`;
    const encodedMessage = encodeURIComponent(whatsappMessage);
    window.open(`https://wa.me/555199380857/?text=${encodedMessage}`, '_blank');
  };

  return (
    <>
      <div className={styles.contactContainer}>
        <Navbar Home="/" Contact="#" openServices={openServices} closeServices={closeServicesFunction} />
        <div className={styles.bg} />
        {openServiceAbout ? (
          <Services isOpen={openServiceAbout} serviceId={serviceId} />
        ) : (
          <>
            <div className={styles.contactContent}>
              <div className={styles.contactTitle}>
                <div className={styles.line}>
                  <TitleText colorText="#2D2D2D" text="Nós podemos te ajudar!" />
                  <TitleText colorText="#2D2D2D" text="Fale conosco" />
                  <SubTexts
                    colorText="#424040"
                    text="Estamos sempre disponíveis para lhe atender e dar o melhor atendimento que você merece!"
                  />
                  <div className={styles.contactText}>
                    <SpanTexts colorText="#424040" text="Tem alguma dúvida?" />
                    <SpanTexts colorText="#424040" text="Preencha o formulário ao lado ou acesse nossos canais abaixo!" />
                  </div>
                </div>
              </div>
              <div className={styles.contactForm}>
                <TitleText colorText="#2D2D2D" text="Entre em contato" />
                <form onSubmit={(e) => e.preventDefault()}>
                  <input type="text" placeholder="Seu nome:" value={name} onChange={(e) => setName(e.target.value)} required />
                  <input type="email" placeholder="Seu email:" value={email} onChange={(e) => setEmail(e.target.value)} required />
                  <input type="text" placeholder="Seu telefone:" value={phone} onChange={(e) => setPhone(e.target.value)} required />
                  <textarea placeholder="Mensagem" value={message} onChange={(e) => setMessage(e.target.value)} required></textarea>
                  <Button onClick={handleWhatsAppMessage} text="Enviar" />
                </form>
              </div>
            </div>
            <div className={styles.White}>
              <SpanTexts colorText="#424040" text="(51) 999380857" icon={<FaPhone />} />
              <SpanTexts colorText="#424040" text="daniel.agrimensor@gmail.com" icon={<IoIosMail />} />
            </div>
          </>
        )}
      </div>
      <Footer />
    </>
  );
}
