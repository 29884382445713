import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyCuSFDfPCvWdI6pfpDKdoviSpXUQe6jgrk",
    authDomain: "geodesia-6b445.firebaseapp.com",
    projectId: "geodesia-6b445",
    storageBucket: "geodesia-6b445.appspot.com",
    messagingSenderId: "110639454199",
    appId: "1:110639454199:web:907a736f822612156c00f2",
    measurementId: "G-SLRP7KNTMQ"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };