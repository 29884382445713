import React, { useState, useEffect } from 'react';
import { db, storage } from '../../firebase';
import { collection, getDocs, doc, setDoc, getDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import ProtectedRoute from '../../AuthRoute';

interface FormData {
  title?: string;
  description?: string;
  images?: FileList | null;
}

const PostForm: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({});
  const [error, setError] = useState<string | null>(null);
  const [serviceId, setServiceId] = useState<string>('');
  const [services, setServices] = useState<any[]>([]); 
  const [existingImages, setExistingImages] = useState<string[]>([]); 

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'posts'));
        const servicesList: any[] = [];
        querySnapshot.forEach((doc) => {
          servicesList.push({ id: doc.id, ...doc.data() });
        });
        setServices(servicesList);
      } catch (error) {
        console.error('Erro ao buscar serviços: ', error);
        setError('Erro ao buscar serviços: ' + error.message);
      }
    };

    fetchServices(); 
  }, []);

  useEffect(() => {
    if (serviceId) {
      const fetchService = async () => {
        try {
          const docRef = doc(db, 'posts', serviceId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            setFormData({
              title: data.title,
              description: data.description,
            });
            setExistingImages(data.imageUrls || []); 
          } else {
            console.log('Nenhum documento encontrado!');
          }
        } catch (error) {
          console.error('Erro ao buscar documento: ', error);
          setError('Erro ao buscar documento: ' + error.message);
        }
      };

      fetchService();
    }
  }, [serviceId]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files) {
      setFormData(prev => ({ ...prev, images: files }));
    }
  };

  const removeImage = (imageUrl: string) => {
    setExistingImages(prev => prev.filter(url => url !== imageUrl));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);

    if (!formData.title) {
      setError('O título é obrigatório.');
      return;
    }

    try {
      let docRef;
      if (serviceId) {
        docRef = doc(db, 'posts', serviceId);
      } else {
        docRef = doc(db, 'posts', formData.title);
      }

      await setDoc(docRef, {
        title: formData.title,
        description: formData.description,
        imageUrls: existingImages,
      });

      if (formData.images) {
        const imageUploadPromises = Array.from(formData.images).map(async (image) => {
          const imageRef = ref(storage, `posts/${formData.title}/${image.name}`);
          await uploadBytes(imageRef, image);
          const imageUrl = await getDownloadURL(imageRef);
          return imageUrl;
        });

        const newImageUrls = await Promise.all(imageUploadPromises);

        await setDoc(docRef, {
          imageUrls: [...existingImages, ...newImageUrls]
        }, { merge: true });
      }

      console.log('Documento escrito com ID: ', formData.title);
    } catch (error) {
      console.error('Erro ao adicionar documento: ', error);
      setError('Falha ao adicionar documento: ' + error.message);
    }
  };

  return (
    <ProtectedRoute>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="serviceId">Selecione um serviço existente:</label>
          <select
            id="serviceId"
            name="serviceId"
            value={serviceId}
            onChange={(e) => setServiceId(e.target.value)}
          >
            <option value="">Novo serviço</option>
            {services.map(service => (
              <option key={service.id} value={service.id}>
                {service.title}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="title">Título:</label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title || ''}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="description">Descrição:</label>
          <textarea
            id="description"
            name="description"
            value={formData.description || ''}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="images">Imagens:</label>
          <input
            type="file"
            id="images"
            name="images"
            onChange={handleImageChange}
            multiple
          />
        </div>
        <div>
          <label>Imagens Existentes:</label>
          <div>
            {existingImages.map((imageUrl, index) => (
              <div key={index}>
                <img src={imageUrl} alt={`Imagem ${index}`} style={{ width: '100px' }} />
                <button type="button" onClick={() => removeImage(imageUrl)}>Remover</button>
              </div>
            ))}
          </div>
        </div>
        <button type="submit">Enviar</button>
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </form>
    </ProtectedRoute>
  );
};

export default PostForm;
