import { useEffect, useState } from 'react';
import { SpanTexts, TitleText } from '../../UI/Text';
import styles from './styles.module.scss';
import image from '../../../assets/about/63339b4b-91cb-41ca-b07a-48f8c02b2912 1.png';
import layer from '../../../assets/about/63339b4b-91cb-41ca-b07a-48f8c02b2912 2.png';
import icons from '../../../assets/about/icons.png';
import Services from '../../../Pages/Services';

interface AboutProps {
  openServicesProps: string | null;
  animationStep: number;
}

export default function About({ openServicesProps, animationStep }: AboutProps) {
  const [isOpen, setIsOpen] = useState(openServicesProps !== null);
  const [serviceId, setServiceId] = useState(openServicesProps);

  useEffect(() => {
    setIsOpen(openServicesProps !== null);
    setServiceId(openServicesProps);
  }, [openServicesProps]);

  return (
    <>
      <div className={styles.containerAbout}>
      {isOpen ? (
        <Services isOpen={isOpen} serviceId={serviceId} />
      ) : (
        <div className={styles.containerAboutItem}>
        <>
        <div className={styles.aboutContainer}>
            <div className={styles.aboutCard}>
              <div className={styles.Texts}>
                <TitleText colorText='#424040' text='Geodésia & Topografia' />
                <SpanTexts
                  colorText='#424040'
                  text='Operamos em todas as áreas da Geodésia, Topografia e Agrimensura em geral, com especialização e longa experiência nas demandas forenses, seja atuando como Perito do Juízo, na Assistência Técnica às Partes ou, ainda, apoiando outros profissionais designados.'
                  />
              </div>
              
            </div>
          </div>
          <div className={styles.sessionCard}>
            <div className={styles.sessionTexts}>
              <TitleText colorText='#424040' text='Diretor Técnico: Daniel Sidnei da Silva ' />
              <SpanTexts
                colorText='#424040'
                text='Detentor do prêmio missão Cartográfica Austríaca, concedido pelo Serviço Geografico do Exército Brasileiro por ter se destacado através de sua contribuição em prol das atividades cartográficas.'
                />
              <SpanTexts
                colorText='#424040'
                text=' Revisor Técnico dos Cap IV – Georreferenciamento e V – Terrenos de Marinha e Acrescidos de Marinha da consagrada obra do Prof Eng L. R. Charnaux Sertã Jr. - Perícia Judicial – Fundamentos, Ferramentas e Meio Ambiente – 2019 – 569p – Editora Progresso'
                />
            </div>
          </div>
        </>
      </div>
      )}
    </div>
      </>
  );
}
