import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const token = localStorage.getItem('authToken');

  if (!token) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
};

const AccessRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const token = localStorage.getItem('authToken');
  if (token) {
    return <Navigate to="/post" />;
  }

  return <>{children}</>;
};

export { AccessRoute };
export default ProtectedRoute;
