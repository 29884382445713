import logo from '../../../assets/logo.png';
import styles from './styles.module.scss';

type LogoProps = {
    width: number;
    height: number;
}


const Logo = ({width, height}: LogoProps) => {
    return(
    <>
        <image className={styles.logo}  style={{width: width, height: height, display: "flex"}}/>    
    </>
    )
}
const  LogoBG = ({width, height}: LogoProps) => {
    return(
    <>
        <image className={styles.logoBG}  style={{width: width, height: height, display: "flex"}}/>    
    </>
    )
}

export  {Logo, LogoBG};