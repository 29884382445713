import { useState } from "react";
import About from "./app/components/About";
import Footer from "./app/components/Footer";
import Navbar from "./app/components/Navbar";
import styles from './styles/styles.module.scss';


function App() {
  const [openServiceAbout, setOpenService] = useState<string | null>(null);
  const [animationStep, setAnimationStep] = useState(0);

  const openServices = (id: string) => {
    setOpenService(id);
    setAnimationStep(1);
  };

  const closeServicesFunction = () => {
    setOpenService(null);
  };


  return (
    <div className={styles.appContainer}>
      <div className={styles.bg} />
      <div className={styles.container}>
        <Navbar
          Home="/"
          Contact="/contact"
          openServices={openServices}
          closeServices={closeServicesFunction}
        />
        <About 
          openServicesProps={openServiceAbout}
          animationStep={animationStep}
        />
      </div>
      <Footer />
    </div>
  );
}

export default App;
